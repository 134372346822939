import { State } from "@progress/kendo-data-query";
import {
    MultiSelectChangeEvent,
    MultiSelectProps,
} from "@progress/kendo-react-dropdowns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/configureStore";
import { AssetTemplateModel } from "../../../store/features/asset/asset-api-slice";
import {
    GridColumnDef,
    removeAllFilters,
    setGridState,
    setSelectedColumns,
    setSelectedTemplates,
} from "../../../store/features/assets-grid/assetsGridSlice";
import { useGetAssetsTemplatesByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import useCombinedAssetsTemplates from "./useCombinedAssetsTemplates";

export type UseAssetsPersistedGridFilters = {
    gridState: State;
    setGridState: (state: State) => {
        payload: State;
        type: string;
    };
    selectedTemplates: AssetTemplateModel[];
    selectedColumns: GridColumnDef[];
    templatesSelectProps: MultiSelectProps;
    columnsSelectProps: MultiSelectProps;
    setColumnsState: (columns: GridColumnDef[]) => void;
    clearFilters: () => void;
};

export const usePersistedAssetsGridState = (
    organisationId: string,
    defaultColumns: GridColumnDef[],
): UseAssetsPersistedGridFilters => {
    const dispatch = useDispatch();
    const { selectedTemplates, selectedColumns, gridState } = useSelector(
        (state: RootState) => state.assetGridFilters,
    );

    const { data: templates } =
        useGetAssetsTemplatesByOrgIdQuery(organisationId);

    const allColumns = useCombinedAssetsTemplates(
        selectedTemplates,
        templates,
        defaultColumns,
    );

    const allColumnsNames = React.useMemo(
        () => allColumns.map(({ title }) => title),
        [allColumns],
    );

    const setColumnsState = React.useCallback(
        (columns: GridColumnDef[]) => {
            dispatch(setSelectedColumns(columns));
        },
        [dispatch],
    );

    React.useEffect(() => {
        if (templates?.length === 1) {
            dispatch(setSelectedTemplates(templates));
            dispatch(setSelectedColumns(allColumns));
        } else {
            dispatch(
                setSelectedColumns(
                    selectedColumns.filter((column) =>
                        allColumnsNames.find((name) => name === column.title),
                    ),
                ),
            );

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [JSON.stringify(selectedTemplates)]);

    const selectedColumnsNames = React.useMemo(
        () =>
            selectedColumns
                .map(({ title }) => title)
                .reduce((accumulator: string[], current: string) => {
                    if (
                        current !== "Name" &&
                        current !== "Description" &&
                        !accumulator.includes(current)
                    ) {
                        accumulator.push(current);
                    }
                    return accumulator;
                }, []),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(selectedColumns)],
    );

    const onAssetTemplateDropdownChange = React.useCallback(
        ({ value }: MultiSelectChangeEvent) => {
            dispatch(
                setSelectedTemplates(
                    value.map((name: string) =>
                        templates.find((t) => t.name === name),
                    ),
                ),
            );
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(templates)],
    );

    const onColumnsDropdownChange = React.useCallback(
        ({ value }: MultiSelectChangeEvent) => {
            dispatch(
                setSelectedColumns(
                    allColumns.filter(({ title }) =>
                        (value as string[]).find((name) => name === title),
                    ),
                ),
            );
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(allColumns)],
    );

    const templatesNames = React.useMemo(
        () => templates?.map(({ name }) => name) ?? [],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(templates)],
    );

    const templatesSelectProps = React.useMemo(
        () => ({
            onChange: onAssetTemplateDropdownChange,
            className: "assets-templates-multiselect",
            placeholder: "Select templates",
            data: templates?.map(({ name }) => name) ?? [],
            value: selectedTemplates?.map(({ name }) => name) ?? [],
            tags:
                selectedTemplates.length > 1
                    ? [
                          {
                              text: `${selectedTemplates.length} templates selected`,
                              data: templatesNames,
                          },
                      ]
                    : null,
        }),
        [
            onAssetTemplateDropdownChange,
            selectedTemplates,
            templates,
            templatesNames,
        ],
    );

    const columnsSelectProps = React.useMemo(
        () => ({
            className: "assets-templates-multiselect",
            onChange: onColumnsDropdownChange,
            placeholder: "Select columns",
            data: allColumnsNames,
            value: selectedColumnsNames,
            tags:
                selectedColumnsNames.length > defaultColumns.length
                    ? [
                          {
                              text: `${selectedColumnsNames.length} columns selected`,
                              data: selectedColumnsNames,
                          },
                      ]
                    : [],
        }),
        [
            allColumnsNames,
            onColumnsDropdownChange,
            selectedColumnsNames,
            defaultColumns.length,
        ],
    );

    return {
        gridState,
        setGridState: (state: State) => dispatch(setGridState(state)),
        selectedTemplates,
        selectedColumns: [
            ...defaultColumns,
            ...selectedColumns.filter(
                (column) =>
                    column.field !== "name" && column.field !== "description",
            ),
        ],
        templatesSelectProps,
        columnsSelectProps,
        setColumnsState,
        clearFilters: () => dispatch(removeAllFilters()),
    };
};

import React, { useMemo } from "react";
import { useParams } from "react-router";
import BridgeConditionIndexSpeedComponent from "../components/bci-components/BridgeConditionIndexSpeedComponent";
import { subsetConfigs } from "./orgReportsBciConfigs";

const titleConfigs = {
    speedometerGraph: [
        { bciTitleName: "Bridge Condition Index by Component Group For" },
        { bciTitleName: "Bridge Condition Index by Defect Group For" },
    ],
};

const OrgReportsBciGaugePage: React.FC = () => {
    const { orgShortName } = useParams<{ orgShortName: string }>();

    const getOrgSpecificCharts = useMemo(() => {
        return subsetConfigs[orgShortName]?.flatMap((config) => {
            return [
                ...titleConfigs.speedometerGraph.map((titleConfig) => (
                    <BridgeConditionIndexSpeedComponent
                        key={`${config.bciConfigName}-${titleConfig.bciTitleName}`}
                        title={`${titleConfig.bciTitleName} ${config.displayName}`}
                        bciConfigName={config.bciConfigName}
                    />
                )),
            ];
        });
    }, [orgShortName]);

    return (
        <div>
            <h1>BCI Gauges</h1>
            <BridgeConditionIndexSpeedComponent
                title="Bridge Condition Index by Component Group"
                bciConfigName={orgShortName + "-bridge"}
            />
            <BridgeConditionIndexSpeedComponent
                title="Bridge Condition Index by Defect Category"
                bciConfigName={orgShortName + "-bridge"}
            />
            {getOrgSpecificCharts}
        </div>
    );
};

export default OrgReportsBciGaugePage;

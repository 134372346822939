// This should be renamed to OrganisationRoutes and moved to "router".
// All usages in api slices and api services should be removed. This is only for frontend routes
const OrganisationConstants = {
    actionCreate: "/create",
    RoutePath: "/organisation",
    Overview: "/organisation-overview",
    Process: "/process",
    Report: "/report",
    Result: "/result",
    help: "help",
    users: "users",
    defects: "defects",
    get userGroups(): string {
        return `${this.users}/groups`;
    },
    get invitations(): string {
        return `${this.users}/invitations`;
    },
    files: "files",
    get fileSearch(): string {
        return `${this.files}/search`;
    },
    Asset: "/asset",
    file: "file",
    get folder(): string {
        return `${this.files}/folder`;
    },
    get siteWorks(): string {
        return `${this.Report}/siteWorks`;
    },
    get inspections(): string {
        return `${this.Report}/inspections`;
    },
    get defectReport(): string {
        return `${this.Report}/defects`;
    },
    get bridgeConditionIndices(): string {
        return `${this.Report}/bridgeConditionIndices`;
    },
    get bridgeConditionIndicesGauge(): string {
        return `${this.Report}/bridgeConditionIndicesGauge`;
    },
    get RoutePathCreate(): string {
        return `${this.RoutePath}${this.actionCreate}`;
    },
    get FormCreate(): string {
        return `${this.Form}${this.actionCreate}`;
    },
    get ProcessCreate(): string {
        return `${this.Process}${this.actionCreate}`;
    },
    get AssetCreate(): string {
        return `${this.Asset}${this.actionCreate}`;
    },
    User: "/user",
    Permission: "/permission",
    get ReportCreate(): string {
        return `${this.Report}${this.actionCreate}`;
    },
    AvailableUsers: "/available-users",
    AvailablePermissions: "/available-permissions",
    GetOrganisationForm: "GetById",
    queryParamScheduledStart: "startDate",
    userGroupApi: "/usergroups",
    HelpItems: "/help-items",
    TagHints: "/tags",
};

export default OrganisationConstants;

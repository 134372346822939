import {
    ListItemProps,
    MultiSelect,
    MultiSelectChangeEvent,
    MultiSelectProps,
} from "@progress/kendo-react-dropdowns";
import * as React from "react";
import { useCallback, useState, useEffect } from "react";

interface Props extends MultiSelectProps {
    defaultOptions: string[];
    tooltip?: string;
}

const AssetMultiSelect: React.FC<Props> = (props) => {
    const selectAllText = "Select all";
    const [isSelectedAll, setIsSelectedAll] = useState(false);

    const enrichedData = [selectAllText].concat(props.data);

    useEffect(() => {
        const totalValuesCount =
            props.value.length + props.defaultOptions.length;

        const optionsCount = props.data.length;
        if (totalValuesCount === optionsCount && !isSelectedAll) {
            setIsSelectedAll(true);
        } else if (totalValuesCount !== optionsCount && isSelectedAll) {
            setIsSelectedAll(false);
        }
    }, [
        isSelectedAll,
        props.value.length,
        props.data.length,
        props.defaultOptions.length,
    ]);

    const onChange = (event: MultiSelectChangeEvent) => {
        const values = event.value as string[];
        const containsSelectAll = values.includes(selectAllText);
        if (containsSelectAll && !isSelectedAll) {
            setIsSelectedAll(true);
            event.value = props.data;
        } else if (containsSelectAll && isSelectedAll) {
            setIsSelectedAll(false);
            event.value = [];
        }

        props.onChange(event);
    };
    const renderer = useCallback(
        (
            li: React.ReactElement<
                HTMLLIElement,
                string | React.JSXElementConstructor<unknown>
            >,
            itemProps: ListItemProps,
        ) => {
            const { dataItem, selected } = itemProps;
            const isDefault = props.defaultOptions.includes(dataItem);
            const isSelectAll = dataItem === selectAllText && isSelectedAll;

            const child = (
                <span>
                    <input
                        type="checkbox"
                        name={dataItem}
                        checked={isDefault || isSelectAll || selected}
                        disabled={isDefault}
                        onChange={(e) => {
                            if (!isDefault)
                                itemProps.onClick(
                                    itemProps.index,
                                    e as unknown as React.MouseEvent<
                                        HTMLLIElement,
                                        MouseEvent
                                    >,
                                );
                        }}
                    />
                    &nbsp;{li.props.children}
                </span>
            );

            return isDefault ? (
                <li className="k-item" style={{ color: "#d1d1d1" }}>
                    {child}
                </li>
            ) : (
                React.cloneElement(li, li.props, child)
            );
        },
        [isSelectedAll, props],
    );

    return (
        <span
            className="d-inline-block"
            id={props?.id}
            data-toggle="tooltip"
            title={props.tooltip}
        >
            <MultiSelect
                {...props}
                data={enrichedData}
                defaultValue={props?.data?.length > 1 ? props.data[0] : []}
                onChange={onChange}
                itemRender={renderer}
                autoClose={false}
            />
        </span>
    );
};

export default AssetMultiSelect;

import * as React from "react";
import { Button, Card, Collapse } from "react-bootstrap";
import cx from "classnames";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { useGetUnexaminedPartsByReviewId } from "../../query/examinationsReviewQueries";
import {
    useDeleteProcessActivityListItemMutation,
    useGetProcessActivityListByIdQuery,
    usePostProcessActivityListCompleteMutation,
    usePostProcessActivityListCreateListItemMutation,
    usePostProcessActivityListNotApplicableMutation,
    usePostProcessActivityListOpenMutation,
} from "../../../../process-activity-list/store/processActivityListApiSlice";
import ExaminationActivityModal from "../ExaminationGrid/ExaminationActivityModal";
import ButtonSpinner from "../../../../../common/components/button/ButtonSpinner";
import ExaminationGrid from "../ExaminationGrid/ExaminationGrid";
import { UnexaminedPart } from "../../domain/examinationsReview";
import { unexaminedPartsGridMap } from "./unexaminedPartsGridMap";
import ProcessActivityStatusActionBar from "../../../../process-activity/components/ProcessActivityStatusActionBar";
import { resolveActivityState } from "../../../../process-activity/helpers/activityStateHelpers";
import "../ExaminationGrid/examinationGrid.scss";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import ExamContext, {
    ExamGridType,
} from "../../query/examinationsContextProvider";

interface Props {
    processId: string;
    reviewId: string;
    organisationId: string;
}

const UnexaminedParts: React.FC<Props> = ({
    processId,
    reviewId,
    organisationId,
}) => {
    const { activityToEdit, setActivityToEdit } = React.useContext(ExamContext);
    const {
        data: unexaminedParts,
        isFetching,
        refetch,
    } = useGetUnexaminedPartsByReviewId(organisationId, reviewId);

    const [open, setOpen] = React.useState(true);

    const { data: parentActivity, isLoading: isParentLoading } =
        useGetProcessActivityListByIdQuery(
            {
                activityId: unexaminedParts?.activityId,
                processId: processId,
            },
            { skip: isFetching || !unexaminedParts?.activityId },
        );

    const { isComplete, isNotApplicable } =
        resolveActivityState(parentActivity);

    let headerCss = "activity-header";

    switch (true) {
        case isComplete:
            headerCss = cx(headerCss, "complete");
            break;
        case isNotApplicable:
            headerCss = cx(headerCss, "na");
            break;
        case !isComplete && !isNotApplicable:
            headerCss = cx(headerCss, "open");
            break;
        default:
            break;
    }

    const showCompleteOptions =
        !isParentLoading && parentActivity && !isComplete && !isNotApplicable;

    const [createNewItem, createNewItemResult] =
        usePostProcessActivityListCreateListItemMutation();
    const [deleteListItem, deleteListItemResult] =
        useDeleteProcessActivityListItemMutation();

    const [completeList, completeListResult] =
        usePostProcessActivityListCompleteMutation();
    const [setListNotApplicable, notApplicableListResult] =
        usePostProcessActivityListNotApplicableMutation();
    const [openList, openListResult] = usePostProcessActivityListOpenMutation();

    const handleAdd = (): void => {
        createNewItem(unexaminedParts.activityId);
    };

    React.useEffect(() => {
        if (createNewItemResult.isSuccess) {
            setActivityToEdit({
                activityId: createNewItemResult?.data?.activityId,
                activityType: ExamGridType.UnexaminedPart,
            });
        }
    }, [createNewItemResult, setActivityToEdit]);

    const handleDelete = (activityItemId: string): void => {
        deleteListItem({
            activityId: unexaminedParts.activityId,
            listItemActivityId: activityItemId,
        });
        setActivityToEdit({ activityId: "", activityType: ExamGridType.None });
    };

    React.useEffect(() => {
        if (deleteListItemResult.isSuccess) {
            refetch();
        }
    }, [deleteListItemResult, refetch]);

    const clickOpen = (): void => {
        openList({ activity: parentActivity });
    };

    if (isFetching) return <CoveringLoadingPanel />;

    return (
        <Card>
            <Card.Header as="h5" className={headerCss}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <Button
                            onClick={() => setOpen(!open)}
                            variant="link"
                            className="mr-2 p-0"
                            style={{ color: "#35373d" }}
                            aria-controls="open"
                            aria-expanded={open}
                        >
                            {open ? <FaAngleUp /> : <FaAngleDown />}
                        </Button>
                        <span>Unexamined Parts</span>
                    </div>
                    {showCompleteOptions && (
                        <ProcessActivityStatusActionBar
                            activity={parentActivity}
                            notApplicable={{
                                actionCallback: setListNotApplicable,
                                actionResult: notApplicableListResult,
                            }}
                            complete={{
                                actionCallback: completeList,
                                actionResult: completeListResult,
                            }}
                        />
                    )}
                    {isComplete && (
                        <Button
                            variant="danger"
                            onClick={clickOpen}
                            disabled={openListResult.isLoading}
                        >
                            Open
                            {openListResult.isLoading && <ButtonSpinner />}
                        </Button>
                    )}
                </div>
            </Card.Header>
            <Collapse in={open} dimension="height">
                <Card.Body>
                    {activityToEdit.activityId !== "" &&
                        activityToEdit.activityType ===
                            ExamGridType.UnexaminedPart &&
                        ((createNewItemResult ?? true) ||
                            createNewItemResult?.isSuccess) && (
                            <ExaminationActivityModal
                                title={`Edit ${activityToEdit.activityType}`}
                                activityId={activityToEdit.activityId}
                                processId={processId}
                                organisationId={organisationId}
                                onClose={() => {
                                    setActivityToEdit({
                                        activityId: "",
                                        activityType: ExamGridType.None,
                                    });
                                    refetch();
                                }}
                            />
                        )}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            className="mr-1"
                            onClick={handleAdd}
                            disabled={
                                createNewItemResult.isLoading ||
                                isComplete ||
                                isNotApplicable
                            }
                        >
                            Add
                            {createNewItemResult.isLoading && <ButtonSpinner />}
                        </Button>
                    </div>
                    <ExaminationGrid<UnexaminedPart>
                        data={unexaminedParts.data}
                        isLoading={isFetching}
                        processId={processId}
                        activityType={ExamGridType.UnexaminedPart}
                        columnsDefinitions={unexaminedPartsGridMap}
                        onDelete={handleDelete}
                        initialSortField={"part"}
                    />
                </Card.Body>
            </Collapse>
        </Card>
    );
};

export default UnexaminedParts;
